/* eslint-disable */
import {
    signInWithEmailAndPassword,
    auth,
    createUserWithEmailAndPassword,
} from "@/config/firebase";
import {
    GoogleAuthProvider,
    signInWithPopup,
    sendPasswordResetEmail,
    signOut,
    getAuth,
} from "firebase/auth";
import {UserModel} from "@/models/user";
import router from "@/router";
import {getErrorMessage} from "@/utils/firebaseErrorCodes";
import {PB_API_instance} from "@/config/axios_config";

const state = {
    loading: false,
    loading_custom: false,
    loading_google: false,
    error: false,
};

const getters = {
    getLoading: (state) => state.loading,
    getCustomLoading: (state) => state.loading_custom,
    getGoogleLoading: (state) => state.loading_google,
    getError: (state) => state.error,
};

const mutations = {
    toggle_loading_stat(state, value) {
        state.loading = value;
    },
    toggle_custom_loading(state, value) {
        state.loading_custom = value;
    },
    toggle_google_loading(state, value) {
        state.loading_google = value;
    },
    toggle_error_state(state, value) {
        state.error = value;
    },
    reset(state) {
        state.loading = false;
        state.error = false;
    },
};

const actions = {


    async onboard_sign_up_with_email_and_password({commit, dispatch}, [email, _password]: [string, string]) {

        try {
            commit("toggle_custom_loading", true);
            await createUserWithEmailAndPassword(auth, email, _password);

            return Promise.resolve({
                message: "Sign up Successful!!!",
            });
        } catch (error: any) {
            return Promise.reject({
                message: error.code ? getErrorMessage(error.code) : error.message,
            });
        } finally {
            commit("toggle_custom_loading", false);
        }
    },

    async onboard_login_with_email_and_password({commit, dispatch}, [email, password]: [string, string]) {

        try {
            commit("toggle_custom_loading", true);
            await signInWithEmailAndPassword(auth, email, password);

            const response = await dispatch("user/check_if_user_detail_exist_in_db", null, {root: true});
            return Promise.resolve({statusCode: response.statusCode});
        } catch (error: any) {
            return Promise.reject({message: getErrorMessage(error.code)});
        } finally {
            commit("toggle_custom_loading", false);
        }
    },

    async onboard_sign_in_with_google({commit, dispatch}) {
        try {
            commit("toggle_google_loading", true);
            // Start a sign in process for an unauthenticated user.
            const provider = new GoogleAuthProvider();
            provider.addScope("profile");
            provider.addScope("email");

            let userCredential = await signInWithPopup(auth, provider);

            let userDetails = new UserModel();
            userDetails.authType = "google";
            userDetails.profilePicture = typeof userCredential.user?.photoURL === 'string' ? userCredential.user?.photoURL : '';
            userDetails.phone = typeof userCredential.user?.phoneNumber === 'string' ? userCredential.user?.phoneNumber : '';
            userDetails.email = userCredential.user?.email as string;
            if (userCredential.user?.displayName !== null) {
                const [firstName, lastName] = (userCredential.user?.displayName as string).split(" ");
                userDetails.firstName = firstName ? firstName : '';
                userDetails.surname = lastName ? lastName : '';
            }
            // The signed-in user info.
            const response1 = await dispatch("user/check_if_user_detail_exist_in_db", null, {root: true});

            return Promise.resolve({
                code: response1.statusCode,
                userDetails: userDetails.toMongoJSON(),
            });
        } catch (error: any) {
            // Handle Errors here.
            return Promise.reject({message: error.code ? getErrorMessage(error.code) : error.message});
        } finally {
            commit("toggle_google_loading", false);
        }
    },

    async sign_up_with_email_and_password({commit, dispatch}, [userDetails, _password]: [UserModel, string]) {

        try {
            commit("toggle_loading_stat", true);
            const userCredential = await createUserWithEmailAndPassword(auth, userDetails.email, _password);
            userDetails.authType = 'custom';
            await dispatch("user/save_user_to_db", [userCredential, userDetails], {root: true});

            await dispatch("user/init_user", null, {root: true});
            await dispatch("analytics/logSignupEvent", {method: "email_password"}, {root: true});

            return Promise.resolve({
                message: "Sign up Successful!!!",
                status: true,
            });
        } catch (error: any) {
            return Promise.reject({
                message: error.code ? getErrorMessage(error.code) : error.message,
                status: false,
            });
        } finally {
            commit("toggle_loading_stat", false);
        }
    },

    async generateCustomToken({commit}) {
        try {
            commit("toggle_loading_stat", true);
            const response: Record<string, string> = (await PB_API_instance.get('user/generate-custom-token')).data;
            return Promise.resolve({link: response.link});
        } catch (error: any) {
            return Promise.reject({message: error.code ? getErrorMessage(error.code) : error.message});
        } finally {
            commit("toggle_loading_stat", false);
        }
    },

    async login_with_email_and_password({commit, dispatch}, [email, password]: [string, string]) {

        try {
            commit("toggle_loading_stat", true);
            await signInWithEmailAndPassword(auth, email, password);

            await dispatch("user/init_user", null, {root: true});
            await dispatch("analytics/logLoginEvent", {method: "email_password"}, {root: true});
            commit("toggle_loading_stat", false);
            return Promise.resolve("Login successful");
        } catch (error: any) {
            // dispatch('logout')
            commit("toggle_loading_stat", false);
            return Promise.reject({message: getErrorMessage(error.code)});
        }
    },

    async sign_in_with_google({dispatch}) {
        try {
            let userDetails = new UserModel();
            userDetails.authType = "google";
            // Start a sign in process for an unauthenticated user.
            const provider = new GoogleAuthProvider();
            provider.addScope("profile");
            provider.addScope("email");

            let userCredential = await signInWithPopup(auth, provider);
            // The signed-in user info.
            const response1 = await dispatch("user/check_if_user_detail_exist_in_db", null, {root: true});

            if (response1.statusCode === 200) {

                dispatch("analytics/logLoginEvent", {method: "google"}, {root: true});
                return Promise.resolve({
                    code: 200,
                    status: true,
                    message: "Log in Successful",
                    isNewUser: false,
                });

            } else {
                await dispatch("user/save_user_to_db", [userCredential, userDetails], {root: true});
                dispatch("analytics/logSignupEvent", {method: "google"}, {root: true});
                return Promise.resolve({
                    code: 200,
                    status: true,
                    message: "Log in Successful",
                    isNewUser: true,
                });
            }
        } catch (error: any) {
            // Handle Errors here.
            return Promise.reject({message: error.code ? getErrorMessage(error.code) : error.message});
        }
    },

    async reset_password({commit}, _email: string) {

        try {
            commit("toggle_loading_stat", true);
            await sendPasswordResetEmail(auth, _email);
            return Promise.resolve({
                message: ` Successfully sent password reset Email to ${_email}`,
                status: true,
            });
        } catch (e: any) {
            return Promise.reject({
                message: e.code ? getErrorMessage(e.code) : e.message,
            });
        } finally {
            commit("toggle_loading_stat", false);
        }
    },

    async logout({commit}) {
        try {
            commit("reset");
            commit("user/reset", null, {root: true});
            const auth = getAuth();
            await signOut(auth);
        } catch (e: any) {
        }
        if (router.currentRoute.value.name !== "home") {
            return router.push({name: "home"});
        } else {
            return router.go(0);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
