/* eslint-disable */
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "@/config/firebase";
import firebase from "firebase/compat";
import UserCredential = firebase.auth.UserCredential;
import {UserModel} from "@/models/user";
import {PB_API_instance} from "@/config/axios_config";
import {plainToInstance} from "class-transformer";

const state = {
    user: new UserModel(),
    authType: "",
    loading: false,
    loggedIn: false,
};

const getters = {
    get_user: (state) => state.user,
    get_auth_type: (state) => state.authType,
    is_loggedIn(state) {
        onAuthStateChanged(auth, (user) => {
            state.loggedIn = Boolean(user);
        });
        return state.loggedIn;
    },
    is_loading: (state) => state.loading,
};

const mutations = {
    set_user(state, payload) {
        state.user = payload;
    },
    set_auth_type(state, payload) {
        state.authType = payload;
    },
    set_loading(state, value) {
        state.loading = value;
    },
    reset(state) {
        state.user = {id: "", data: {}};
        state.authType = "";
        state.loading = false;
    },
};

const actions = {
    async init_user({commit}) {
        try {
            const response = await PB_API_instance.get('user/fetch-user-from-db');
            if (response.status === 200) {
                commit('set_user', UserModel.fromMongoDB(response.data));
                return Promise.resolve(null);
            } else throw new Error('User does not exist or is not properly authenticated')
        } catch (e) {
            return Promise.reject(e);
        }
    },

    async check_if_user_detail_exist_in_db({commit}) {
        try {
            const response = await PB_API_instance.get('user/fetch-user-from-db');
            response.status === 200 ? commit('set_user', UserModel.fromMongoDB(response.data)) : null;
            return Promise.resolve({statusCode: response.status});
        } catch (e) {
            return Promise.reject({message: 'Error encountered fetching user details'});
        }
    },

    async refresh_user_details({commit}) {
        try {
            const response = await PB_API_instance.get('user/fetch-user-from-db');
            if (response.status === 200) {
                commit('set_user', UserModel.fromMongoDB(response.data));
                return Promise.resolve(null);
            } else throw new Error('User does not exist or is not properly authenticated')
        } catch (e) {
            return Promise.reject(e);
        }
    },

    async save_user_to_db({dispatch}, [userCredential, userDetails]: [UserCredential, UserModel]) {
        // const response = new CustomResponse()
        if (userDetails.authType === "google") {
            userDetails.profilePicture = userCredential.user?.photoURL !== null ? userCredential.user?.photoURL as string : userDetails.profilePicture;
            userDetails.phone = userCredential.user?.phoneNumber !== null ? userCredential.user?.phoneNumber as string : userDetails.phone;
            userDetails.email = userCredential.user?.email as string;
            if (userCredential.user?.displayName !== null) {
                const [firstName, lastName] = (userCredential.user?.displayName as string).split(" ");
                userDetails.firstName = firstName ? firstName : '';
                userDetails.surname = lastName ? lastName : '';
            }
        }

        try {
            await PB_API_instance.post('user/save-user-to-db', plainToInstance(UserModel, userDetails).toMongoJSON());
            await dispatch('refresh_user_details')
            return Promise.resolve(null);
        } catch (e) {
            return Promise.reject(e);
        }
    },

    async save_pastor_or_priest_to_db({
                                          commit,
                                          dispatch
                                      }, [userDetails, churchID, image]: [UserModel, string, File | null]) {

        try {
            commit('set_loading', true);

            const response: Record<string, any> = (await PB_API_instance.post('user/save-user-to-db', Object.assign(plainToInstance(UserModel, userDetails).toMongoJSON(), {
                type: 'churchAdmin',
                isChurchAdmin: true,
                isChurchAdminOf: [churchID]
            }))).data;

            const response1: Record<string, any> = (await PB_API_instance.get('user/fetch-user-from-db')).data;

            await dispatch('updateUser', [UserModel.fromMongoDB(response1), image])
            // await dispatch('refresh_user_details')
            return Promise.resolve({
                userDetails: UserModel.fromMongoDB(response),
                message: 'You have successfully onboarded your Parish'
            });
        } catch (e: any) {
            return Promise.reject({message: e.response ? e.response.data.message : e.message});
        } finally {
            commit('set_loading', false);
        }
    },

    async updateUser({commit, dispatch, state}, [user, image]: [UserModel, string | null]) {
        try {
            commit("set_loading", true);

            if (image) {
                user.profilePicture ? await dispatch("utils/deleteImageFromStorage", user.profilePicture, {root: true}) : null;
                user.profilePicture = await dispatch("utils/uploadImageToFirebase", {
                    file: image,
                    folder: `profile_picture_photos/${user.id}`
                }, {root: true});
            }

            await PB_API_instance.patch(`user/${user.id}`, plainToInstance(UserModel, user).toMongoJSON());
            await dispatch("refresh_user_details");

            return Promise.resolve({
                message: "Successfully Updated User",
                status: true,
            });
        } catch (e: any) {
            return Promise.reject({message: e.response ? e.response.data.message : e.message, status: false});
        } finally {
            commit("set_loading", false);
        }
    },
    async reset({commit}) {
        commit("reset");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
